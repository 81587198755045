import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';

import { Input } from '../../../../shared/Input/Input';
import { Select } from '../../../../shared/Select/Select';
import { SelectOption } from '../../../../shared/Select/Select.types';
import Typography from '../../../../shared/Typography/Typography';
import { City, DeliveryOptions } from '../../DeliveryAddress.types';
import { formatCityToOptions } from '../../DeliveryAddress.utils';

import styles from './Delivery.module.css';

type DeliveryProps = {
  onChange: (options: DeliveryOptions) => void;
  cities: City[];
};

export const Delivery = ({ cities, onChange }: DeliveryProps) => {
  const [city, setCity] = useState<SelectOption | null>(null);
  const [address, setAddress] = useState<string>('');

  const handleCityChange = useCallback((value: SelectOption | null) => {
    setCity(value);
  }, []);

  const handleAddressChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setAddress(value),
    [],
  );

  const handleAddressOnBlur = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const chosenCity = cities.find(({ priceTypeID1C }) => priceTypeID1C === city?.value);

      if (chosenCity && value.length > 0) {
        onChange({ address: value, city: chosenCity });
      }
    },
    [city?.value, onChange, cities],
  );

  return (
    <div className={styles.root}>
      <div className={styles.group}>
        <Typography variant="label" component="p" marginBottom={10}>
          Місто
        </Typography>
        <Select
          name="city"
          options={formatCityToOptions<City>(cities)}
          placeholder="Оберіть місто..."
          value={city}
          onChange={handleCityChange}
          defaultMenuIsOpen
        />
      </div>

      {city && (
        <div className={styles.group}>
          <Typography variant="label" component="p" marginBottom={10}>
            Адреса доставки
          </Typography>
          <Input
            placeholder="Вкажіть адресу..."
            value={address}
            onChange={handleAddressChange}
            onBlur={handleAddressOnBlur}
          />
        </div>
      )}
    </div>
  );
};
