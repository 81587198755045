import { City, OfficeDTO, TGetOfficesOptions } from './DeliveryAddress.types';
import { CITIES } from '../../__MOCK__';
import axios from 'axios';

// export function fetchCities() {
//   return new Promise<{ cities: City[] }>((resolve) => setTimeout(() => resolve({ cities: CITIES }), 700));
// }

export async function fetchOffices({ itemId, cityId }: TGetOfficesOptions): Promise<OfficeDTO> {
  const { data } = await axios.get<OfficeDTO>(`${process.env.REACT_APP_API_PATH}product/${itemId}/?city=${cityId}`);

  return data;
}
export async function fetchCities(): Promise<City[]> {
  const { data } = await axios.get<City[]>(`${process.env.REACT_APP_API_PATH}price_type/list/`);
  const formatCites = data.filter(({ typeName }) => typeName !== 'Продаж' && typeName !== 'Закупка');
  return formatCites;
}
