import React, { useCallback, useState } from 'react';
import { Select } from '../../../../shared/Select/Select';
import { SelectOption } from '../../../../shared/Select/Select.types';
import Typography from '../../../../shared/Typography/Typography';
import { isSameDay, isWeekday } from '../../DeliveryDateTIme.utils';
import { WeekdayTimeEnum, WeekendTimeEnum } from './TimeSelect.enums';
import { TimeSelectProps, WEEKDAY_TIME_OPTION_MAP, WEEKEND_TIME_OPTION_MAP } from './TimeSelect.types';
import styles from './TimeSelect.module.css';

export const TimeSelect = ({ onChange, chosenDate }: TimeSelectProps) => {
  const [time, setTime] = useState<SelectOption | null>(null);
  const timeNow = new Date().getHours();
  console.log(chosenDate, '<=== date ===');
  const isWeekdayChosen = chosenDate ? isWeekday(new Date(chosenDate)) : null;

  const TIME_RANGE: SelectOption[] = Object.values(isWeekdayChosen ? WeekdayTimeEnum : WeekendTimeEnum).map((key) => ({
    label: isWeekdayChosen
      ? WEEKDAY_TIME_OPTION_MAP.get(key)?.label ?? ''
      : WEEKEND_TIME_OPTION_MAP.get(key)?.label ?? '',
    value: key,
  }));

  const filterOptions: SelectOption[] = isSameDay(chosenDate)
    ? TIME_RANGE.filter((range) => {
        const value = isWeekdayChosen
          ? WEEKDAY_TIME_OPTION_MAP.get(range.value as WeekdayTimeEnum)?.to
          : WEEKEND_TIME_OPTION_MAP.get(range.value as WeekendTimeEnum)?.to;
        return value && timeNow < value;
      })
    : TIME_RANGE;

  const handleChange = useCallback(
    (optionValue: SelectOption | null) => {
      const timeOption = isWeekdayChosen
        ? WEEKDAY_TIME_OPTION_MAP.get(optionValue?.value as WeekdayTimeEnum)
        : WEEKEND_TIME_OPTION_MAP.get(optionValue?.value as WeekendTimeEnum);
      const hasOptionValue = isWeekdayChosen
        ? WEEKDAY_TIME_OPTION_MAP.get(optionValue?.value as WeekdayTimeEnum)
        : WEEKEND_TIME_OPTION_MAP.get(optionValue?.value as WeekendTimeEnum);

      if (optionValue?.value && hasOptionValue && timeOption) {
        onChange(timeOption);
      }

      setTime(optionValue);
    },
    [isWeekdayChosen, onChange],
  );

  return (
    <div className={styles.dateTimeWrapper}>
      <Typography align="center" variant="h2" component="h2" marginBottom={15}>
        До якого часу резервувати товар?
      </Typography>
      <Select
        optionCenter
        singleValueCenter
        name="time"
        options={filterOptions}
        placeholder="Оберіть зручний проміжок часу..."
        value={time}
        onChange={handleChange}
        defaultMenuIsOpen
      />
    </div>
  );
};
