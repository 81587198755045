import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { choseFullName, selectClientFullName, selectClientId } from './clientInfoSlice';
import { FullName } from './components/FullName/FullName';
import { PhoneNumber } from './components/PhoneNumber/PhoneNumber';

export const ClientInfo = () => {
  const dispatch = useAppDispatch();
  const clientFullName = useAppSelector(selectClientFullName);
  const clientId = useAppSelector(selectClientId);

  const handleFullNameOnBlur = useCallback(
    (value: string) => {
      dispatch(choseFullName({ fullName: value }));
    },
    [dispatch],
  );

  return (
    <>
      <PhoneNumber />
      <FullName chosenFullName={clientFullName} clientId={clientId} onBlur={handleFullNameOnBlur} />
    </>
  );
};
