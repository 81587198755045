import { WeekdayTimeEnum, WeekendTimeEnum } from './TimeSelect.enums';

export type TimeOption = {
  from: number;
  to: number;
  minutes: number;
  label: string;
};
export type TimeSelectProps = {
  chosenDate: string;

  onChange: (time: TimeOption) => void;
};

export const WEEKDAY_TIME_OPTION_MAP = new Map<WeekdayTimeEnum, TimeOption>()
  .set(WeekdayTimeEnum.ELEVEN, {
    from: 8,
    to: 11,
    minutes: 0,
    label: '8:00 - 11:00',
  })
  .set(WeekdayTimeEnum.FOURTEEN, {
    from: 11,
    to: 14,
    minutes: 0,
    label: '11:00 - 14:00',
  })
  .set(WeekdayTimeEnum.SEVENTEEN, {
    from: 14,
    to: 17,
    minutes: 30,
    label: '14:00 - 17:30',
  });

export const WEEKEND_TIME_OPTION_MAP = new Map<WeekendTimeEnum, TimeOption>()
  .set(WeekendTimeEnum.ELEVEN, {
    from: 9,
    to: 11,
    minutes: 0,
    label: '9:00 - 11:00',
  })
  .set(WeekendTimeEnum.THIRTEEN, {
    from: 11,
    to: 13,
    minutes: 0,
    label: '11:00 - 13:00',
  })
  .set(WeekendTimeEnum.FOURTEEN, {
    from: 13,
    to: 14,
    minutes: 30,
    label: '13:00 - 14:30',
  });
