import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import Typography from '../../../../shared/Typography/Typography';
import { choseFullName, chosePhone, getClients, setClientId } from '../../clientInfoSlice';
import { PhoneConfirmStepsEnum } from '../../ClientInfo.enums';
import styles from './PhoneNumber.module.css';
import { CodeInputGroup } from './components/CodeInputGroup';
import { PhoneInputGroup } from './components/PhoneInputGroup';
import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/Check.svg';

const SECONDS_TO_WAIT = 30;

export const PhoneNumber = () => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState<PhoneConfirmStepsEnum>(PhoneConfirmStepsEnum.START);
  const [secondsLeft, setSecondsLeft] = useState<number>(SECONDS_TO_WAIT);
  const [isRetryAvailable, setIsRetryAvailable] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    const timer =
      secondsLeft > 0 &&
      currentStep === PhoneConfirmStepsEnum.CODE_SEND &&
      setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
    if (secondsLeft === 0) setIsRetryAvailable(true);
    return () => {
      timer && clearInterval(timer);
    };
  }, [currentStep, secondsLeft]);

  const handleNext = useCallback(async (value: string) => {
    setPhone(value);

    setCurrentStep(value ? PhoneConfirmStepsEnum.CODE_SEND : PhoneConfirmStepsEnum.START);
  }, []);

  const handleCodeConfirm = useCallback(
    async (phone: string) => {
      try {
        const date = await dispatch(getClients(phone));
        dispatch(chosePhone({ phone }));
        setCurrentStep(PhoneConfirmStepsEnum.CODE_VERIFIED);
        // if (date.payload && !(date.payload as { fullName: string | null; id: string | null }).id) {

        // }
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch],
  );

  const isDisabled = !isRetryAvailable;
  return (
    <>
      <Typography variant="h2" component="h2" align="center" marginBottom={15}>
        Номер телефону {currentStep === PhoneConfirmStepsEnum.CODE_VERIFIED && <CheckIcon className={styles.icon} />}
      </Typography>

      <PhoneInputGroup onNext={handleNext} />
      {currentStep === PhoneConfirmStepsEnum.CODE_SEND && (
        <CodeInputGroup phone={phone} isDisabled={isDisabled} onConfirm={handleCodeConfirm} />
      )}
    </>
  );
};
