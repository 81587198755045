import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { GeneralInfoType, Prices } from './GeneralInfo.types';
import { fetchGeneralInfo } from './generalInfoAPI';

export type GeneralInfoState = {
  prices: Prices | null;
  name: string;
  photo: string;
  loading: 'idle' | 'loading' | 'failed';
  itemID1C: string;
};

const initialState: GeneralInfoState = {
  loading: 'idle',
  prices: null,
  name: '',
  photo: '',
  itemID1C: '',
};

const NAME = 'generalInfo';

export const getGeneralInfo = createAsyncThunk(`${NAME}/fetchGeneralInfo`, async (data: GeneralInfoType[]) => {
  const info = await fetchGeneralInfo(data[0] ?? {});
  return info;
});

export const generalInfoSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralInfo.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.prices = action.payload.prices;
        state.itemID1C = action.payload.itemID1C;
        state.name = action.payload.name;
        state.photo = action.payload.item_photo;
      })
      .addCase(getGeneralInfo.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getGeneralInfo.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const {} = generalInfoSlice.actions;
export const selectPrices = (state: RootState) => state.generalInfo.prices;
export const selectItemId = (state: RootState) => state.generalInfo.itemID1C;
export const selectItemPhoto = (state: RootState) => state.generalInfo.photo;
export const selectItemName = (state: RootState) => state.generalInfo.name;

export default generalInfoSlice.reducer;
