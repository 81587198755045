import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { createUser, bookItem } from './bookingAPI';
import { CreateUser, BookItem } from './Booking.types';

export type BookingState = {
  status: 'idle' | 'loading' | 'failed';
};

const NAME = 'booking';
const initialState: BookingState = {
  status: 'idle',
};

export const createUserThunk = createAsyncThunk(`${NAME}/createUser`, async (user: CreateUser) => {
  const data = await createUser(user);
  return data;
});

export const bookItemThunk = createAsyncThunk(`${NAME}/bookItem`, async (item: BookItem) => {
  const data = await bookItem(item);
  return data;
});

export const bookingSlice = createSlice({
  initialState,
  name: NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUserThunk.fulfilled, (state, action) => {
        state.status = 'idle';
      })

      .addCase(bookItemThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(bookItemThunk.fulfilled, (state, action) => {
        state.status = 'idle';
      });
  },
});

export const {} = bookingSlice.actions;

export default bookingSlice.reducer;
