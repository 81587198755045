import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import cls from 'classnames';

import styles from './Input.module.css';

type InputProps<V> = {
  name?: Path<V>;
  register?: UseFormRegister<V>;
  className?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function Input<V>({ register, name, className = '', ...rest }: InputProps<V>) {
  const inputClassName = cls(styles.root, className);
  if (!register || !name) {
    return <input {...rest} className={inputClassName} />;
  }

  return <input {...register(name)} {...rest} className={inputClassName} />;
}
