import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type ClientCommentState = {
  comment: string;
};

const initialState: ClientCommentState = {
  comment: '',
};

const NAME = 'clientComment';

export const clientCommentSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    choseComment: (state, action: PayloadAction<Pick<ClientCommentState, 'comment'>>) => {
      state.comment = action.payload.comment;
    },
  },
});

export const { choseComment } = clientCommentSlice.actions;
export const selectClientComment = (state: RootState) => state.clientComment.comment;

export default clientCommentSlice.reducer;
