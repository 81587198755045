import React, { MouseEventHandler, ChangeEvent, useCallback, useState } from 'react';
import InputMask from 'react-input-mask';

import { Input } from '../../../../../shared/Input/Input';
import { Button } from '../../../../../shared/Button/Button';
import { generateCode, sendSms } from '../../../ClientInfo.utils';
import styles from '../PhoneNumber.module.css';
import { setClientId, choseFullName, chosePhone } from '../../../clientInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';

type PhoneInputGroupProps = {
  onNext: (value: string) => void;
};
const PHONE_REGEX = new RegExp('^\\+380\\s\\d{2}\\s\\d{3}\\s\\d{2}\\s\\d{2}$');

export const PhoneInputGroup = ({ onNext }: PhoneInputGroupProps) => {
  const [phone, setPhone] = useState<string>('');
  const [showButton, setShowButton] = useState<boolean>(true);
  const invalid = !PHONE_REGEX.test(phone);
  const dispatch = useAppDispatch();
  const handleOnChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setPhone(value);
  }, []);
  const handleOnInputClick = useCallback(() => {
    setPhone('');
    onNext('');
    dispatch(chosePhone({ phone: null }));
    setShowButton(true);
    dispatch(setClientId({ id: null }));
    dispatch(choseFullName({ fullName: null }));
  }, [dispatch, onNext]);

  const handleConfirm = useCallback(() => {
    if (onNext && phone) {
      setShowButton(false);
      onNext(phone);
    }
  }, [onNext, phone]);

  return (
    <>
      <div className={styles.group}>
        <InputMask
          mask="+380 99 999 99 99"
          value={phone}
          onClick={handleOnInputClick}
          onChange={handleOnChange}
          maskPlaceholder="X"
        >
          {(props: any) => <Input className={styles.input} {...props} placeholder="+380 XX XXX XX XX" />}
        </InputMask>

        {showButton && !invalid && (
          <Button onClick={handleConfirm} disabled={invalid} className={styles.button}>
            Далі
          </Button>
        )}
      </div>
    </>
  );
};
