import React, { useCallback, useEffect } from "react";
import Typography from "../../shared/Typography/Typography";
import styles from "./GeneralInfo.module.css";
import { DropDown } from "./components/DropDown/DropDown";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectPrices,
  selectItemPhoto,
  selectItemName,
  getGeneralInfo,
} from "./generalInfoSlice";
import { GeneralInfoType } from "./GeneralInfo.types";
import { MOCK_API_DATA } from "../../__MOCK__";
import { userAction } from "../Analtics/analiticsAPI";

export const GeneralInfo = () => {
  const prices = useAppSelector(selectPrices);
  const photo = useAppSelector(selectItemPhoto);
  const name = useAppSelector(selectItemName);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   // FIXME: FOR MOCK remove on prod

  //   const setMock = async () => {
  //     await dispatch(getGeneralInfo(MOCK_API_DATA));
  //   };

  //   setMock();
  // }, [dispatch]);

  const handleParentMessage = useCallback(
    async (event: MessageEvent) => {
      try {
        console.log(event.data);
        if (
          event.origin === "https://budprokat.kiev.ua" &&
          (event.data as GeneralInfoType[])[0]?.itemID1C
        ) {
          await dispatch(getGeneralInfo(event.data as GeneralInfoType[]));
        }
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("message", handleParentMessage);
    userAction({ step_name: "Открыта модалка" });
    return () => {
      window.removeEventListener("message", handleParentMessage);
    };
  }, [handleParentMessage]);

  return (
    <div className={styles.generalInfoWrapper}>
      <img className={styles.generalInfoImage} src={photo} alt={name} />
      <div>
        <Typography marginBottom={5} variant="h2" component="h2">
          {name}
        </Typography>
        {prices && <DropDown prices={prices} />}
      </div>
    </div>
  );
};
