import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import bookingReducer from '../features/Booking/bookingSlice';
import deliveryDateTimeReducer from '../features/DeliveryDateTime/deliveryDateTimeSlice';
import deliveryAddressReducer from '../features/DeliveryAddress/deliveryAddressSlice';
import generalInfoReducer from '../features/GeneralInfo/generalInfoSlice';
import clientInfoReducer from '../features/ClientInfo/clientInfoSlice';
import clientCommentReducer from '../features/ClientComment/clientCommentSlice';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    booking: bookingReducer,
    deliveryDateTime: deliveryDateTimeReducer,
    deliveryAddress: deliveryAddressReducer,
    generalInfo: generalInfoReducer,
    clientInfo: clientInfoReducer,
    clientComment: clientCommentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
