import React, { useEffect } from 'react';

import { ReactComponent as Checked } from '../../assets/icons/Checked.svg';
import Typography from '../../shared/Typography/Typography';
import styles from './SuccessScreen.module.css';

export const SuccessScreen = () => {
  useEffect(() => {
    setTimeout(() => {
      if (window.parent !== null) {
        window.parent.location.href = 'https://budprokat.kiev.ua/';
        window.parent.postMessage('close', '*');
      }
    }, 5000);
  }, []);

  return (
    <div className={styles.successScreenWrapper}>
      <Checked className={styles.svg} />
      <Typography className={styles.successTitle}>Ваше замовлення прийнято!</Typography>
      <Typography className={styles.successSubtitle}>Наш менеджер зв'яжеться з вами найближчим часом.</Typography>
    </div>
  );
};
