import React, { useCallback, useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Button } from '../../shared/Button/Button';
import Typography from '../../shared/Typography/Typography';

import {
  choseDeliveryOptions,
  choseDeliveryType,
  getCities,
  getOffices,
  selectCities,
  clearDeliveryType,
  selectOffices,
  selectSelfDeliveryCityId,
  choseSelfDeliveryCity,
  choseSelfDeliveryOffice,
  selectSelfDeliveryOfficeWorkSchedule,
} from './deliveryAddressSlice';
import { Delivery } from './components/Delivery/Delivery';
import { City, Office, DeliveryOptions } from './DeliveryAddress.types';
import { DeliveryTypeEnum } from './DeliveryAddress.enums';
import styles from './DeliveryAddress.module.css';
import { SelfDelivery } from './components/SelfDelivery/SelfDelivery';
import { selectItemId } from '../GeneralInfo/generalInfoSlice';

export function DeliveryAddress() {
  const cities = useAppSelector(selectCities);
  const offices = useAppSelector(selectOffices);
  const itemId = useAppSelector(selectItemId);
  const cityId = useAppSelector(selectSelfDeliveryCityId);
  const officeWorkSchedule = useAppSelector(selectSelfDeliveryOfficeWorkSchedule);
  const dispatch = useAppDispatch();
  const [deliveryType, setDeliveryType] = useState<DeliveryTypeEnum | null>(null);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        if (cityId && itemId) {
          await dispatch(getOffices({ itemId, cityId }));
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchOffices();
  }, [cityId, dispatch, itemId]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        await dispatch(getCities());
      } catch (err) {
        console.error(err);
      }
    };

    fetchCities();
  }, [dispatch]);

  const handleClick = useCallback(
    (type: DeliveryTypeEnum) => () => {
      if (deliveryType !== type) {
        setDeliveryType(type);
        dispatch(clearDeliveryType());
        dispatch(choseDeliveryType({ deliveryType: type }));
      }
    },
    [dispatch, deliveryType],
  );

  const handleDeliveryChange = useCallback(
    (data: DeliveryOptions) => {
      dispatch(choseDeliveryOptions({ deliveryOptions: data }));
    },
    [dispatch],
  );

  const handleCityChange = useCallback(
    (data: City) => {
      dispatch(choseSelfDeliveryCity(data));
    },
    [dispatch],
  );
  const handleOfficeChange = useCallback(
    (data: Office) => {
      dispatch(choseSelfDeliveryOffice(data));
    },
    [dispatch],
  );

  return (
    <div className={styles.root}>
      <Typography variant="h2" component="h2" align="center" marginBottom="15px">
        Спосіб доставки
      </Typography>

      <div className={styles.deliveryType}>
        <Button
          variant={deliveryType === DeliveryTypeEnum.SELF_DELIVERY ? 'main' : 'outline'}
          onClick={handleClick(DeliveryTypeEnum.SELF_DELIVERY)}
        >
          Самовивіз
        </Button>
        <Button
          variant={deliveryType === DeliveryTypeEnum.DELIVERY ? 'main' : 'outline'}
          onClick={handleClick(DeliveryTypeEnum.DELIVERY)}
        >
          Доставка
        </Button>
      </div>

      {deliveryType === DeliveryTypeEnum.DELIVERY && <Delivery cities={cities} onChange={handleDeliveryChange} />}
      {deliveryType === DeliveryTypeEnum.SELF_DELIVERY && (
        <SelfDelivery
          officeWorkSchedule={officeWorkSchedule}
          offices={offices}
          cities={cities}
          onCityChange={handleCityChange}
          onOfficeChange={handleOfficeChange}
        />
      )}
    </div>
  );
}
