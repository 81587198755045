import moment from 'moment';

export const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

export const getLabel = (time: number) => `${time.toString().padStart(2, '0')}:00`;

export const getRangeLabel = (range: { from: number; to: number }) =>
  `${range.from.toString().padStart(2, '0')}:00 - ${range.to.toString().padStart(2, '0')}:00`;

export const getTimeOptions = (selectedDate: Date) => {
  const weekday = selectedDate.getDay();
  if (weekday > 0 && weekday < 6) return range(8, 19).map((item) => ({ value: item, label: getLabel(item) }));
  return range(9, 16).map((item) => ({ value: item, label: getLabel(item) }));
};

export const isWorkingHours = (isWeekday: boolean): boolean => {
  const time = Number(moment().format('H'));
  const timeBegin = isWeekday ? 8 : 9;
  const timeEnd = isWeekday ? 17 : 14;
  return time > timeBegin && time < timeEnd;
};

// export const isTomorrow = (from: Date, to: Date) => new Date(from.getTime() + 24 * 60 * 60 * 1000) === to;

export const addDay = (day: Date, count: number) => new Date(day.getTime() + 24 * 60 * 60 * 1000 * count);

export const isWeekday = (selectedDate: Date) => {
  const weekday = selectedDate.getDay();
  return weekday > 0 && weekday < 6;
};

export const isSunday = (selectedDate: Date) => {
  const weekday = selectedDate.getDay();
  return weekday === 0;
};

export const prepareDate = (date: Date) => moment(date).utc().toISOString();

export const isSameDay = (day: Date | string) => {
  const today = new Date();
  const selectDay = new Date(day);
  return today.getDate() === selectDay.getDate();
};
