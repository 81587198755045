import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import InputMask from "react-input-mask";
import cls from "classnames";

import { Button } from "../../../../../shared/Button/Button";
import Typography from "../../../../../shared/Typography/Typography";
import { Input } from "../../../../../shared/Input/Input";

import styles from "../PhoneNumber.module.css";
import { generateCode, sendSms } from "../../../ClientInfo.utils";
import { userAction } from "../../../../Analtics/analiticsAPI";

type CodeInputGroupProps = {
  isDisabled: boolean;
  phone: string;
  onConfirm: (code: string) => void;
};

const CODE_REGEX = new RegExp("^\\d{2}\\s\\d{2}$");

export const CodeInputGroup = ({
  isDisabled,
  onConfirm,
  phone,
}: CodeInputGroupProps) => {
  const [code, setCode] = useState<string>("");
  const [generatedCode, setGeneratedCode] = useState<string | null>(null);
  const invalid = !CODE_REGEX.test(code);
  const isCodeSame = code.replace(/\s/, "") === generatedCode;

  const sendCode = useCallback(async () => {
    const generatedCode = generateCode(4);
    setGeneratedCode(generatedCode);

    try {
      const success = await sendSms({
        phone,
        text: `Ваш код підтвердження: ${generatedCode}`,
      });
      userAction({ step_name: "Отправлено сообщение" });
    } catch (err) {
      console.error(err);
    }
  }, [phone]);

  useEffect(() => {
    sendCode();
  }, [sendCode]);

  const handleOnChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setCode(value);
    },
    []
  );

  const handleOnResend = useCallback(() => {
    setCode("");
    sendCode();
  }, [sendCode]);

  const handleConfirm = useCallback(() => {
    if (phone && isCodeSame) {
      onConfirm(phone);
      userAction({ step_name: "Номер подтвержден" });
    }
  }, [phone, isCodeSame, onConfirm]);

  return (
    <div className={styles.root}>
      <Typography variant="h2" component="h2" align="left" marginBottom={15}>
        Введіть код із СМС
      </Typography>
      <InputMask
        autoFocus
        mask="99 99"
        value={code}
        onChange={handleOnChange}
        maskPlaceholder="X"
      >
        {(props: any) => (
          <Input {...props} className={styles.input} placeholder="XX XX" />
        )}
      </InputMask>
      <div className={styles.flex}>
        <div>
          <Typography>Не прийшов код?</Typography>
          <Typography
            onClick={handleOnResend}
            className={cls(styles.link, isDisabled && styles.disabled)}
          >
            Надіслати повторно
          </Typography>
        </div>
        <Button
          onClick={handleConfirm}
          disabled={invalid}
          className={styles.button}
        >
          Підтвердити
        </Button>
      </div>
    </div>
  );
};
