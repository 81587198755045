export enum WeekdayTimeEnum {
  ELEVEN = '11',
  FOURTEEN = '14',
  SEVENTEEN = '17',
}
export enum WeekendTimeEnum {
  ELEVEN = '11',
  THIRTEEN = '13',
  FOURTEEN = '14',
}
