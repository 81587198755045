import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Typography from '../../../../shared/Typography/Typography';
import { Input } from '../../../../shared/Input/Input';
import styles from './FullName.module.css';
import { choseFullName } from '../../clientInfoSlice';
import { useAppDispatch } from '../../../../app/hooks';

type FullNameProps = {
  chosenFullName?: null | string;
  clientId?: null | string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
};

export const FullName = ({ clientId, chosenFullName, onBlur, onChange }: FullNameProps) => {
  const [fullName, setFullName] = useState<string>('');
  const dispatch = useAppDispatch();
  const isChosenFullNameCorrect = Boolean(clientId);

  useEffect(() => {
    if (chosenFullName) {
      return setFullName(chosenFullName);
    }
    setFullName('');
  }, [chosenFullName]);

  const handleOnChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setFullName(value);
      dispatch(choseFullName({ fullName: value }));
      if (onChange && value) {
        onChange(value);
      }
    },
    [dispatch, onChange],
  );
  // const handleOnBlur = useCallback(
  //   ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
  //     if (onBlur && value) {
  //       onBlur(value);
  //     }
  //   },
  //   [onBlur],
  // );

  return (
    <div className={styles.root}>
      <Typography variant="h2" component="h2" align="center" marginBottom={15}>
        Ім’я та прізвище
      </Typography>

      <Input
        disabled={isChosenFullNameCorrect}
        placeholder="Введіть власне ім’я та прізвище..."
        value={fullName}
        onChange={handleOnChange}
        // onBlur={handleOnBlur}
      />
    </div>
  );
};
