import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchClients } from './clientInfoAPI';

export type ClientInfoState = {
  fullName: null | string;
  phone: null | string;
  id: null | string;
  loading: 'idle' | 'loading' | 'failed';
};

const initialState: ClientInfoState = {
  id: null,
  fullName: null,
  phone: null,
  loading: 'idle',
};

const NAME = 'clientInfo';

export const getClients = createAsyncThunk(`${NAME}/fetchClients`, async (phone: string) => {
  const data = await fetchClients(phone);

  return data;
});

export const clientInfoSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    choseFullName: (state, action: PayloadAction<Pick<ClientInfoState, 'fullName'>>) => {
      state.fullName = action.payload.fullName;
    },
    chosePhone: (state, action: PayloadAction<Pick<ClientInfoState, 'phone'>>) => {
      state.phone = action.payload.phone;
    },
    setClientId: (state, action: PayloadAction<Pick<ClientInfoState, 'id'>>) => {
      state.id = action.payload.id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.fulfilled, (state, action) => {
        state.loading = 'idle';

        if (action.payload.fullName && action.payload.id) {
          state.fullName = action.payload.fullName;
          state.id = action.payload.id;
        }
      })

      .addCase(getClients.pending, (state) => {
        state.loading = 'loading';
      })

      .addCase(getClients.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { choseFullName, chosePhone, setClientId } = clientInfoSlice.actions;
export const selectClientFullName = (state: RootState) => state.clientInfo.fullName;
export const selectClientPhone = (state: RootState) => state.clientInfo.phone;
export const selectClientId = (state: RootState) => state.clientInfo.id;

export default clientInfoSlice.reducer;
