import axios from 'axios';
import { getAllPhoneVariants } from './ClientInfo.utils';
import { Clients } from './ClientInfo.types';

export async function fetchClients(phone: string): Promise<{ fullName: string | null; id: string | null }> {
  const allPhones = getAllPhoneVariants(phone).join(',');

  const {
    data: { count, results },
  } = await axios.get<Clients>(`${process.env.REACT_APP_API_PATH}client/list/?phone=${allPhones}`);

  const userInfo = count === 1 ? results[0] : null;

  return {
    fullName: userInfo?.FIO ?? null,
    id: userInfo?.id ?? null,
  };
}
