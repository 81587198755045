import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState, AppThunk } from '../../app/store';
import { TimeOption } from './components/TimeSelect/TimeSelect.types';

export type DeliveryDateTimeState = {
  chosenDate: null | string;
  chosenTime: null | TimeOption;
};

const initialState: DeliveryDateTimeState = {
  chosenDate: null,
  chosenTime: null,
};

export const deliveryDateTimeSlice = createSlice({
  name: 'deliveryDateTime',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // Use the PayloadAction type to declare the contents of `action.payload`

    choseDate: (state, action: PayloadAction<Pick<DeliveryDateTimeState, 'chosenDate'>>) => {
      state.chosenDate = action.payload.chosenDate;
    },
    choseTime: (state, action: PayloadAction<Pick<DeliveryDateTimeState, 'chosenTime'>>) => {
      state.chosenTime = action.payload.chosenTime;
    },
    clearDateTime: (state) => {
      state.chosenTime = null;
      state.chosenDate = null;
    },
  },
});

export const { choseDate, choseTime, clearDateTime } = deliveryDateTimeSlice.actions;
export const selectChosenDate = (state: RootState) => state.deliveryDateTime.chosenDate;
export const selectChosenTime = (state: RootState) => state.deliveryDateTime.chosenTime;

export default deliveryDateTimeSlice.reducer;
