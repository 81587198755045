import { OfficeStock, City } from './DeliveryAddress.types';
import { SelectOption } from '../../shared/Select/Select.types';

export const formatCityToOptions = <C extends City>(arr: C[]): SelectOption[] =>
  arr.map(({ priceTypeID1C, typeName }) => ({
    value: priceTypeID1C,
    label: typeName,
  }));

export const formatOfficeToOptions = <C extends OfficeStock>(arr: C[]): SelectOption[] =>
  arr.map(({ stockID1C, stockAddress }) => ({
    value: stockID1C,
    label: stockAddress,
  }));
