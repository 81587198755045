import { CreateUser, BookItem, CreateUserDTO } from './Booking.types';
import axios from 'axios';

export async function createUser(body: CreateUser) {
  const data: CreateUserDTO = await axios.post(`${process.env.REACT_APP_API_PATH}client/create/`, body);
  return data;
}

export async function bookItem({ chosenItem, comment, clientID, endDateTime, isCreatedByClient, stockID }: BookItem) {
  const data = await axios.post(`${process.env.REACT_APP_API_PATH}transfer_act/create/`, {
    chosenItems: [{ itemID: chosenItem, quantity: 1 }],
    comment,
    clientID,
    endDateTime,
    isCreatedByClient,
    stockID,
  });
  return data;
}
