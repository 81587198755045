import React, { ButtonHTMLAttributes, MouseEventHandler, DetailedHTMLProps, useCallback } from 'react';
import cls from 'classnames';

import styles from './Button.module.css';

type ButtonVariant = 'main' | 'outline';

type ButtonProps = { variant?: ButtonVariant } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = ({
  children,
  variant = 'main',
  type = 'button',
  className = '',
  disabled,
  onClick,
  ...rest
}: ButtonProps) => {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (...rest) => {
      if (onClick && !disabled) {
        onClick(...rest);
      }
    },
    [disabled, onClick],
  );

  return (
    <button
      {...rest}
      type={type}
      onClick={handleOnClick}
      className={cls(styles.root, styles[variant], disabled && styles.disabled, className)}
    >
      {children}
    </button>
  );
};
