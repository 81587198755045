import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { DeliveryTypeEnum } from './DeliveryAddress.enums';
import {
  City,
  Office,
  OfficeDTO,
  DeliveryOptions,
  SelfDeliveryOptions,
  TGetOfficesOptions,
} from './DeliveryAddress.types';
import { fetchCities, fetchOffices } from './deliveryAddressAPI';

export type DeliveryAddressState = {
  cities: City[];
  offices: OfficeDTO;
  loading: 'idle' | 'loading' | 'failed';
  deliveryType: null | DeliveryTypeEnum;
  deliveryOptions: null | DeliveryOptions;
  selfDeliveryOptions: SelfDeliveryOptions;
};

const initialState: DeliveryAddressState = {
  loading: 'idle',
  cities: [],
  offices: {
    stocks: [],
  },
  deliveryType: null,
  deliveryOptions: null,
  selfDeliveryOptions: {
    city: null,
    office: null,
  },
};

const NAME = 'deliveryAddress';

export const getCities = createAsyncThunk(`${NAME}/fetchCities`, async () => {
  const cities = await fetchCities();

  return cities;
});

export const getOffices = createAsyncThunk(`${NAME}/fetchOffices`, async (options: TGetOfficesOptions) => {
  const data = await fetchOffices(options);

  return data;
});

export const deliveryAddressSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    clearDeliveryType: (state) => {
      state.deliveryOptions = initialState.deliveryOptions;
      state.selfDeliveryOptions = initialState.selfDeliveryOptions;
    },
    choseDeliveryType: (state, action: PayloadAction<Pick<DeliveryAddressState, 'deliveryType'>>) => {
      state.deliveryType = action.payload.deliveryType;
    },
    choseDeliveryOptions: (state, action: PayloadAction<Pick<DeliveryAddressState, 'deliveryOptions'>>) => {
      state.deliveryOptions = action.payload.deliveryOptions;
    },
    choseSelfDeliveryOffice: (state, action: PayloadAction<Office>) => {
      state.selfDeliveryOptions.office = action.payload;
    },
    choseSelfDeliveryCity: (state, action: PayloadAction<City>) => {
      state.selfDeliveryOptions.city = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCities.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.cities = action.payload;
      })
      .addCase(getCities.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getCities.rejected, (state) => {
        state.loading = 'failed';
      })

      .addCase(getOffices.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.offices = action.payload;
        state.offices.stocks = action.payload.stocks;
      })
      .addCase(getOffices.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getOffices.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const {
  choseDeliveryOptions,
  choseSelfDeliveryOffice,
  choseSelfDeliveryCity,
  choseDeliveryType,
  clearDeliveryType,
} = deliveryAddressSlice.actions;
export const selectDeliveryType = (state: RootState) => state.deliveryAddress.deliveryType;
export const selectDeliveryOptions = (state: RootState) => state.deliveryAddress.deliveryOptions;
export const selectSelfDeliveryOptions = (state: RootState) => state.deliveryAddress.selfDeliveryOptions;
export const selectSelfDeliveryCityId = (state: RootState) =>
  state.deliveryAddress.selfDeliveryOptions.city?.priceTypeID1C;
export const selectCities = (state: RootState) => state.deliveryAddress.cities;
export const selectOffices = (state: RootState) => state.deliveryAddress.offices;
export const selectSelfDeliveryOfficeId = (state: RootState) =>
  state.deliveryAddress.selfDeliveryOptions.office?.stockID1C;
export const selectSelfDeliveryOfficeWorkSchedule = (state: RootState) =>
  state.deliveryAddress.selfDeliveryOptions.office?.workSchedule;

export default deliveryAddressSlice.reducer;
