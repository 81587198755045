import React, { useCallback } from 'react';
import DatePicker, { BeforeAfterModifier, DaysOfWeekModifier } from 'react-day-picker';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/uk';
import 'react-day-picker/lib/style.css';
import './DayPicker.css';
import { isWeekday, isWorkingHours, addDay, isSameDay } from '../../DeliveryDateTIme.utils';

type DatePickerProps = {
  setDate: (day: Date) => void;

  date?: Date;
};

export const DayPicker = ({ date, setDate }: DatePickerProps) => {
  const handleDayClick = useCallback(
    (day: Date, modifiers: any) => {
      if (modifiers?.disabled) {
        return;
      }

      if (isSameDay(day)) {
        if (!isWeekday(day) && !isWorkingHours(isWeekday(day))) {
          return setDate(addDay(day, 2));
        }
        if (isWeekday(day) && !isWorkingHours(isWeekday(day))) {
          return setDate(addDay(day, 1));
        }
      }
      return setDate(day);
    },
    [setDate],
  );

  const day = new Date();
  const daysLimit = new Date(day.setDate(day.getDate() + 7));
  const modifiers = { saturday: { daysOfWeek: [6] }, sunday: { daysOfWeek: [0] } };
  const disabledDays: [DaysOfWeekModifier, BeforeAfterModifier] = [
    { daysOfWeek: [0] },
    { before: new Date(), after: daysLimit },
  ];
  const modifiersStyles = {
    sunday: {
      position: 'relative',
    },
  };

  return (
    <DatePicker
      className={'wrapper'}
      numberOfMonths={1}
      showOutsideDays
      selectedDays={date}
      disabledDays={disabledDays}
      modifiers={modifiers}
      onDayClick={handleDayClick}
      localeUtils={MomentLocaleUtils}
      locale="uk"
      month={date}
      modifiersStyles={modifiersStyles}
    />
  );
};
