import React from 'react';
import OriginalSelect, { Props, components, PlaceholderProps } from 'react-select';
import cls from 'classnames';

import { ReactComponent as ArrowIcon } from '../../assets/icons/Arrow.svg';
import styles from './Select.module.css';
import Typography from '../Typography/Typography';

type SelectProps = {
  optionCenter?: boolean;
  singleValueCenter?: boolean;
} & Props;

const IndicatorSeparator = () => null;

const DropdownIndicator: typeof components.DropdownIndicator = ({ selectProps }) => (
  <ArrowIcon className={cls(selectProps.menuIsOpen && styles.up)} />
);

const IndicatorsContainer: typeof components.IndicatorsContainer = (props) => (
  <components.IndicatorsContainer {...props} className={styles.indicatorsContainer} />
);

const Placeholder: typeof components.Placeholder = (props) => {
  return <components.Placeholder {...props} className={styles.placeholder} />;
};

const Option: typeof components.Option = ({ children, ...props }) => (
  <components.Option
    {...props}
    className={cls(styles.option, props.isFocused && styles.optionFocused, props.isSelected && styles.optionSelected)}
  >
    <Typography variant="option" component="div">
      {children}
    </Typography>
  </components.Option>
);

const MenuList: typeof components.MenuList = (props) => <components.MenuList {...props} className={styles.menuList} />;

const Menu: typeof components.Menu = (props) => <components.Menu {...props} className={styles.menu} />;

const Control: typeof components.Control = (props) => <components.Control {...props} className={styles.control} />;

const SingleValue: typeof components.SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props} className={styles.singleValue}>
    <Typography>{children}</Typography>
  </components.SingleValue>
);

const NoOptionsMessage: typeof components.NoOptionsMessage = ({ children, ...props }) => (
  <components.NoOptionsMessage {...props} className={styles.singleValue}>
    <Typography>Нема варіантів</Typography>
  </components.NoOptionsMessage>
);

export const Select = ({ optionCenter = false, singleValueCenter = false, ...props }: SelectProps) => (
  <div className={cls(styles.root, optionCenter && styles.optionCenter, singleValueCenter && styles.singleValueCenter)}>
    <OriginalSelect
      {...props}
      components={{
        DropdownIndicator,
        IndicatorsContainer,
        IndicatorSeparator,
        Placeholder,
        Option,
        MenuList,
        Menu,
        Control,
        SingleValue,
        NoOptionsMessage,
      }}
    />
  </div>
);
