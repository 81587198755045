import React, { useCallback, useState } from "react";

import { Input } from "../../../../shared/Input/Input";
import { Select } from "../../../../shared/Select/Select";
import { SelectOption } from "../../../../shared/Select/Select.types";
import Typography from "../../../../shared/Typography/Typography";
import {
  Office,
  City,
  OfficeDTO,
  OfficeStock,
} from "../../DeliveryAddress.types";
import {
  formatCityToOptions,
  formatOfficeToOptions,
} from "../../DeliveryAddress.utils";
import { clearDeliveryType } from "../../deliveryAddressSlice";

import styles from "./SelfDelivery.module.css";

type SelfDeliveryProps = {
  onCityChange: (city: City) => void;
  onOfficeChange: (office: Office) => void;
  offices: OfficeDTO;
  cities: City[];
  officeWorkSchedule: string | undefined;
};

export const SelfDelivery = ({
  officeWorkSchedule,
  offices,
  onCityChange,
  onOfficeChange,
  cities,
}: SelfDeliveryProps) => {
  const [city, setCity] = useState<SelectOption | null>(null);
  const [office, setOffice] = useState<SelectOption | null>(null);

  const workSchedule = officeWorkSchedule?.split("&");
  console.log(workSchedule, "<=== weekday ===");
  const handleCityChange = useCallback(
    (cityValue: SelectOption | null) => {
      setOffice(null);
      setCity(cityValue);

      const chosenCity = cities.find(
        ({ priceTypeID1C }) => priceTypeID1C === cityValue?.value
      );
      if (chosenCity) {
        onCityChange(chosenCity);
      }
    },
    [cities, onCityChange]
  );

  const handleOfficeChange = useCallback(
    (officeValue: SelectOption | null) => {
      setOffice(officeValue);

      const chosenOffice = offices.stocks.find(
        ({ stockID1C }) => stockID1C === officeValue?.value
      );

      if (chosenOffice) {
        onOfficeChange(chosenOffice);
      }
    },
    [offices.stocks, onOfficeChange]
  );

  return (
    <div className={styles.root}>
      <div className={styles.group}>
        <Typography variant="label" component="p" marginBottom={10}>
          Місто
        </Typography>
        <Select
          name="city"
          options={formatCityToOptions<City>(cities)}
          placeholder="Оберіть місто..."
          value={city}
          onChange={handleCityChange}
          defaultMenuIsOpen
        />
      </div>

      {city && (
        <div className={styles.group}>
          <Typography variant="label" component="p" marginBottom={10}>
            Є в наявності у відділенні
          </Typography>

          <Select
            name="office"
            options={formatOfficeToOptions<OfficeStock>(offices.stocks)}
            placeholder="Оберіть відділення..."
            value={office}
            onChange={handleOfficeChange}
            defaultMenuIsOpen
          />
        </div>
      )}

      {office && (
        <div className={styles.workTime}>
          {workSchedule?.map((time) => (
            <Typography key={time} variant="caption">
              {time}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
};
