import React, { useCallback, useState } from 'react';
import cls from 'classnames';

import Typography from '../../../../shared/Typography/Typography';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/Arrow.svg';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/Question.svg';
import styles from './DropDown.module.css';
import { Prices } from '../../GeneralInfo.types';
import ReactTooltip from 'react-tooltip';

type DropDownProps = {
  prices: Prices;
};

export const DropDown = ({ prices }: DropDownProps) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const handelClick = useCallback(() => {
    setShowDropDown(!showDropDown);
  }, [showDropDown]);

  const DropDownRows = () => {
    const titles = ['1-2 доби', '3-6 доби', '7 діб', 'Застава'];

    return (
      <div className={styles.dropDownContainer}>
        {Object.values(prices).map((price, index) => {
          if (titles[index] === titles[3]) {
            return (
              <div key={price} className={styles.row}>
                <Typography className={styles.collLeft} variant="optionLight" component="p">
                  {titles[index]}
                </Typography>
                <Typography variant="optionLight" component="span">
                  {price} грн за добу <QuestionIcon data-tip data-for="dropDown-tip" className={styles.questionIcon} />
                </Typography>
              </div>
            );
          }
          return (
            <div key={price} className={styles.row}>
              <Typography className={styles.collLeft} variant="optionLight" component="p">
                {titles[index]}
              </Typography>
              <Typography variant="optionLight" component="p">
                {price} грн за добу
              </Typography>
            </div>
          );
        })}

        <ReactTooltip
          id="dropDown-tip"
          place="bottom"
          type="dark"
          effect="solid"
          getContent={() => (
            <p style={{ maxWidth: 200, lineHeight: 1.5 }}>Дійсна за умови прописки в Києві або Київскій області</p>
          )}
        />
      </div>
    );
  };

  return (
    <div className={styles.dropDownWrapper}>
      <Typography className={styles.dropDownTitle} onClick={handelClick} variant="caption" component="span">
        Вартість оренди
      </Typography>
      <ArrowIcon className={cls(showDropDown && styles.up)} />
      {showDropDown ? <DropDownRows /> : null}
    </div>
  );
};
