import React, { PropsWithChildren, createElement, CSSProperties } from 'react';
import cls from 'classnames';

import styles from './Typography.module.css';

type TTypographyAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';
type TTypographyDisplay = 'block' | 'flex' | 'inline-block' | 'inline-flex';
type TTypographyComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p' | 'div';
type TTypographyVariant = 'h2' | 'option' | 'optionLight' | 'caption' | 'label';

type TypographyProps = {
  align?: TTypographyAlign;
  display?: TTypographyDisplay;
  noWrap?: boolean;
  variant?: TTypographyVariant;
  className?: string;
  component?: TTypographyComponent;
  style?: CSSProperties;
  marginBottom?: string | number;
  onClick?: () => void;
};

const Typography = (props: PropsWithChildren<TypographyProps>) => {
  const {
    className = '',
    variant = '',
    align = 'inherit',
    noWrap = false,
    children,
    component = 'span',
    display,
    style,
    marginBottom,
    ...rest
  } = props;

  return createElement(
    component,
    {
      className: cls(
        styles.root,
        variant && styles[variant],
        align !== 'inherit' && styles[align],
        display && styles[display],
        noWrap && styles.noWrap,
        className,
      ),
      style: {
        ...style,
        marginBottom,
      },
      ...rest,
    },
    children,
  );
};

export default Typography;
